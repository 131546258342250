import "./App.css";
import React, { useState, useEffect, Suspense } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
//import Marketplace from "./views/marketplace/index";
//import Admin from "./views/admin/index";
import { useDispatch, useSelector } from "react-redux";
import {
  getProduct,
  getTva,
  getNotif,
  userVerify,
  fetchUserDisconnected,
  itemToCart,
  getNetwork,
  fetchDeletePopupMessage,
  getCarousel,
} from "./lib/state/actions";
import HeartSpinner from "./components/spinner/HeartSpinner";
//import Notif from "./components/admin/popup/Notif";
//import ConfirmRegistration from "./views/marketplace/confirmPages/ConfirmRegistration";
//import ResetPassword from "./views/marketplace/confirmPages/ResetPassword";
//import Liseuse from "./views/marketplace/reader/index";
import styled from "styled-components";
//import "./fonts/segoesc.ttf";
import { clearCart } from "./lib/state/actions";
//import Maintenance from "./views/marketplace/maintenance";
//import LoadingPage from "./views/marketplace/loadingPage";
import {
  useUserState,
  useFetchState,
  useMessageState,
  useAdminState,
  useNetworkState,
  usePopupMessageState,
} from "./views/marketplace/categoryPage/controllers/stateCtrl";
//import Popup from "./components/marketplace/reader/popup/Popup";
//import JapanPage from "./views/marketplace/pageNotFound/japanPage";
/*import ReactGA from "react-ga4";
ReactGA.initialize("G-GBNN0WLNJV");*/

const Marketplace = React.lazy(() => import("./views/marketplace/index"));
const Admin = React.lazy(() => import("./views/admin/index"));
const Notif = React.lazy(() => import("./components/admin/popup/Notif"));
const ConfirmRegistration = React.lazy(() =>
  import("./views/marketplace/confirmPages/ConfirmRegistration")
);
const ResetPassword = React.lazy(() =>
  import("./views/marketplace/confirmPages/ResetPassword")
);
const Liseuse = React.lazy(() => import("./views/marketplace/reader/index"));
const Maintenance = React.lazy(() => import("./views/marketplace/maintenance"));
const LoadingPage = React.lazy(() => import("./views/marketplace/loadingPage"));
const Popup = React.lazy(() =>
  import("./components/marketplace/reader/popup/Popup")
);
const JapanPage = React.lazy(() =>
  import("./views/marketplace/pageNotFound/japanPage")
);

function App() {
  const dispatch = useDispatch();

  const isFetching = useFetchState(useSelector);
  const message = useMessageState(useSelector);

  const { userConnected, userId } = useUserState(useSelector);
  const adminConnected = useAdminState(useSelector);
  const { maintenance, loadingPage, _id } = useNetworkState(useSelector);
  const popupMessage = usePopupMessageState(useSelector);

  //segoesc font is ready
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const navigate = useNavigate();

  //google analytics
  /* useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);*/

  //dispatch network state
  useEffect(() => {
    dispatch(getNetwork());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //products/tva/category state if not maintenance/loadingpage and admin
  useEffect(() => {
    if ((!maintenance && !loadingPage && _id) || adminConnected) {
      dispatch(getProduct());

      //category for users are in index//

      dispatch(getTva());

      dispatch(getCarousel());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminConnected, maintenance, _id, loadingPage]);
  //!!!!!!!!!!!!!!!!! mettre a jour la dépendancce pour charger la bibliotheque lors d'un achat

  //detect country
  function getElementText(response, elementName) {
    return response.getElementsByTagName(elementName)[0].innerHTML;
  }
  function getIpAddress() {
    fetch("https://api.hostip.info/")
      .then((response) => {
        return response.text();
      })
      .then((xml) => {
        return new window.DOMParser().parseFromString(xml, "text/xml");
      })
      .then((xmlDoc) => {
        let countryName = getElementText(xmlDoc, "countryName");
        let countryCode = getElementText(xmlDoc, "countryAbbrev");
        setCountry(countryName);
        setCountryCode(countryCode);
      });
  }
  useEffect(() => {
    //ip
    getIpAddress();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //load all notifs when user is connected
  useEffect(() => {
    if (userId) {
      dispatch(getNotif(userId));

      const storage = JSON.parse(localStorage.getItem(userId));
      if (storage && storage.cart.length > 0) {
        const body = JSON.stringify({ shippingArray: storage.cart });
        dispatch(itemToCart(body, userId));
      }
    } else {
      dispatch(clearCart());
    }
  }, [userId, dispatch]);

  //diconnect at refresh if no token
  let userToken = localStorage.getItem("token");
  useEffect(() => {
    if (!userConnected) {
      if (userToken !== null) {
        dispatch(userVerify());
      } else {
        dispatch(fetchUserDisconnected());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userConnected]);

  //no right
  useEffect(() => {
    const preventDefault = (e) => e.preventDefault();
    document.addEventListener("contextmenu", preventDefault);
    return () => {
      document.removeEventListener("contextmenu", preventDefault);
    };
  });

  return (
    <>
      {isFetching && <HeartSpinner />}
      {/*country === "JAPAN" && (
        <Routes>
          <Route path="/*" element={<JapanPage />} />
        </Routes>
      )*/}
      {country !== "JAPAN" &&
      countryCode !== "JP" &&
      countryCode !== "JPN" &&
      countryCode !== "JPY" ? (
        <>
          <Suspense fallback={<HeartSpinner />}>
            {(maintenance || loadingPage) && adminConnected && (
              <P>
                Navigation en mode Administrateur --- Site en MAINTENANCE ---
                <button
                  onClick={() => navigate("/admin/products")}
                  type="button"
                >
                  administration
                </button>
              </P>
            )}
            {maintenance && !adminConnected && (
              <Routes>
                <Route path="/*" element={<Maintenance />} />
                <Route path="/admin/*" element={<Admin />} />
              </Routes>
            )}
            {loadingPage && !adminConnected && (
              <Routes>
                <Route path="/*" element={<LoadingPage />} />
                <Route path="/admin/*" element={<Admin />} />
              </Routes>
            )}
            {((!maintenance && !loadingPage) ||
              ((maintenance || loadingPage) && adminConnected)) && (
              <>
                {((_id && !maintenance && !loadingPage) || adminConnected) && (
                  <Routes>
                    <Route path="/*" element={<Marketplace />} />
                    <Route path="/admin/*" element={<Admin />} />
                    <Route
                      path="/marketplace/user/validateregistration/:token"
                      element={<ConfirmRegistration />}
                    />
                    <Route
                      path="/marketplace/authentication/resetpassword/:token"
                      element={<ResetPassword />}
                    />
                    <Route
                      path={"/liseuse/:frenchtitle/:productId/:id/:chapterId/*"}
                      element={
                        userConnected ? (
                          <Liseuse extract={false} />
                        ) : (
                          <Marketplace />
                        )
                      }
                    />
                    <Route
                      path={
                        "/liseuse/:frenchtitle/:productId/:id/:chapterId/extract"
                      }
                      element={<Liseuse extract={true} />}
                    />

                    <Route
                      path={
                        "/liseuse/:frenchtitle/:productId/:id/:chapterId/gift"
                      }
                      element={<Liseuse gift={true} />}
                    />
                  </Routes>
                )}
              </>
            )}

            {message && <Notif />}
            {popupMessage && (
              <Popup
                visible={popupMessage ? true : false}
                message={popupMessage}
                togglePopup={() => dispatch(fetchDeletePopupMessage())}
              />
            )}
          </Suspense>
        </>
      ) : (
        <Suspense fallback={<HeartSpinner />}>
          <Routes>
            <Route path="/*" element={<JapanPage />} />
          </Routes>
        </Suspense>
      )}
    </>
  );
}

const P = styled.p`
  color: white;
  background: red;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  padding: 5px 0;
`;

export default App;
